.largeThanksText {
    font-family: 'Streetwear';
    font-size: 8vw;
    text-align: center;
    color: black;
    text-shadow: 1vw 1vw white;

    margin-top: 10vw;
    margin-bottom: 10px;
    margin-left: 0;
}

.percentText {
    font-family: 'WindsorProUltHv';
    font-size: 6.5vw;
    text-align: center;
    color: black;

    margin-top: 1px;
    margin-bottom: 10px;
}

.emailText {
    font-family: 'WindsorProUltHv';
    font-size: 5vw;
    text-align: center;
    color: black;

    margin-top: 0.5vw;
    margin-bottom: 1vw;
    margin-left: 10vw;
    margin-right: 10vw;
}

.subText {
    font-family: 'WindsorProUltHv';
    font-size: 3vw;
    text-align: center;
    color: black;

    margin-top: 0.5vw;
    margin-bottom: 1vw;
    margin-left: 5vw;
    margin-right: 5vw;
}


.submitBox {
    width: 30%;
    
    font-size: 5vw;
    font-family: 'WindsorProUltHv';
    color: black;

    background-color: #d6442b;
    border-radius: 2vw;
    border-color: black;
    

    margin: 1vw 0;
  }

.signUpTypeBox {
    width: 60%;

    font-family: 'WindsorProUltHv';
    font-size: 5vw;

    border-radius: 2vw;
    border-color: black;

    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 2vw;
    margin-right: 1vw;
    margin-left: 2vw;
}

.errorMessage {
    font-size: 3vw;
    font-family: "WindsorProUltHv";
    text-align: center;
    color: #d6442b;

    background-color: white;
    border-radius: 2vw;
    border: solid red 0.5vw;

    margin-top: 1vw;
    margin-bottom: 4vw;
    margin-left: 25vw;
    margin-right: 25vw;
    padding-top: 0.25vw;
}

.linkButton {
    position: absolute;

    font-size: 5vw;
    font-family: "WindsorProUltHv";
    text-align: center;
    color: #d6442b;
    text-decoration: none;

    background-color: white;
    border-radius: 6vw;
    border: 0.5vw solid black;

    margin-top: 25vw;
    margin-left: 35vw;
    padding-left: 2vw;
    padding-right: 2vw;
}

.linkButtonText {
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}