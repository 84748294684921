.header {
    font-family: WindsorProUltHv;
    color: white;
    font-size: 18vw;
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 4px 4px #d16957;
}

.body {
    font-family: HoeflerBlackItalic;
    font-size: 4vw;
    text-align: center;
    margin-top: 2px;
    /* margin-left: 15%;
    margin-right: 15%; */
}

