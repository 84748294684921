.largeDiscountText {
    font-family: 'WindsorProUltHv';
    font-size: 14vw;
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
    text-shadow: 4px 4px #d6442b;
    -webkit-text-stroke: 2px black;
}

.emailText {
    font-family: 'WindsorProUltHv';
    font-size: 5vw;
    margin-top: 1px;
    color: black;
    margin-bottom: 10px;
    margin-left: 8%;
    margin-right: 8%;
}

.submitBox {
    width: 30%;
    
    font-size: 5vw;
    font-family: 'WindsorProUltHv';
    color: black;

    background-color: #d6442b;
    border-radius: 2vw;
    border-color: black;
    

    margin: 1vw 0;
  }

.typeBox {
    width: 60%;
    margin: 8px 0;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 2vw;
    margin-right: 10px;
    border-radius: 2vw;
    font-family: 'WindsorProUltHv';
    border-color: black;
    font-size: 5vw;
}

.errorMessage {
    font-size: 3vw;
    color: #d6442b;

    background-color: white;
    border-radius: 2vw;
    border: solid red 0.5vw;

    margin-top: 1vw;
    margin-bottom: 4vw;
    margin-left: 25vw;
    margin-right: 25vw;
    padding-top: 0.25vw;
}