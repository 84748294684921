.locations {
    text-align: center;
    font-family: 'WindsorProUltHv';
}

.welcome {
    padding-left: 30px;
    padding-right: 30px;
}

.OrderRow {
    display: flex;
  }
  
  .OrderButton {
    flex: 40%;
  }
  
  .order {
    font-size: 5vw;
    font-family: 'WindsorProUltHv';
    color: #d6442b;
    border-color: black;
    border-style: solid;
    border-radius: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 5px 5px black;
    background-color: white;
  }
  
  .order:hover {
    box-shadow: none;
  }
  
  .order-href {
    text-decoration: none;
  }

  .blerb {
    margin-left: 10vw;
    margin-right: 10vw;
    font-size: 4vw;
  }

  .hours {
    font-size: 4.5vw;
    margin-left: 10vw;
    margin-right: 10vw;
    background-color: white;
    padding: 10px;
    border-radius: 15px;
  }

  .orderHeaders {
    font-family: "Streetwear";
    margin-bottom: -10px;
    font-size: 5vw;
  }