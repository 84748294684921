.links {
    text-align: center;
}

.suzyLogo {
    width: 40%;
    align-content: center;
    margin-top: 20px;
}

.OrderButton {
    flex: 40%;
  }

.order {
    font-size: 4vw;
    font-family: 'WindsorProUltHv';
    color: #d6442b;
    border-color: black;
    border-style: solid;
    border-radius: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 90px;
    margin-right: 90px;
    box-shadow: 5px 5px black;
}