.conveyer {
    position: relative;
}

.conveyerBelt {
    width: 100%;
    overflow-x: hidden;
    overflow: hidden;

    margin-bottom: 60vw;
}

.conveyerBeltFrame {
    width: 100%;
    position: absolute;
    left: 0;

    margin-top: 20vw;
}

.conveyerBeltFrameLines {
    width: 100%;
    position: absolute;

    margin-top: 20vw;
}

.frameLines1 {
    left: 0%;

    animation: line1movement 8s linear;
    animation-iteration-count: infinite;
}

.frameLines2 {
    left: 100%;
    
    animation: line2movement 8s linear;
    animation-iteration-count: infinite;
}

@keyframes line1movement
{
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@keyframes line2movement
{
    0% {
        left: 100%;
    }
    100% {
        left: 0%;
    }
}

.lycheetini {
    width: 20vw;
    position: absolute;
    left: 130%;

    margin-top: 3vw;

    animation: lycheetinimovement 8s linear;
    animation-iteration-count: infinite;
}

@keyframes lycheetinimovement
{
    0% {
        left: 130%;
    }
    100% {
        left: 30%;
    }
}

.lycheetini2 {
    width: 20vw;
    position: absolute;
    left: 30%;

    margin-top: 3vw;

    animation: lycheetinimovement2 8s linear;
    animation-iteration-count: infinite;
}

@keyframes lycheetinimovement2
{
    0% {
        left: 30%;
    }
    100% {
        left: -70%;
    }
}

.noodleBowl {
    width: 40vw;
    position: absolute;

    margin-top: 5vw;
}

.noodle1 {
    left: 153%;

    animation: noodleBowlMovement1 8s linear;
    animation-iteration-count: infinite;
}

.noodle2 {
    left: 53%;

    animation: noodleBowlMovement2 8s linear;
    animation-iteration-count: infinite;
}

@keyframes noodleBowlMovement1
{
    0% {
        left: 153%;
    }
    100% {
        left: 53%;
    }
}

@keyframes noodleBowlMovement2
{
    0% {
        left: 53%;
    }
    100% {
        left: -47%;
    }
}

.sojuSunrise {
    width: 9vw;
    position: absolute;

    margin-top: 0.5vw;
}

.soju1 {
    left: 95%;

    animation: sojuSunriseMovement1 8s linear;
    animation-iteration-count: infinite;
}

.soju2 {
    left: 195%;

    animation: sojuSunriseMovement2 8s linear;
    animation-iteration-count: infinite;
}

@keyframes sojuSunriseMovement1
{
    0% {
        left: 95%;
    }
    100% {
        left: -5%;
    }
}

@keyframes sojuSunriseMovement2
{
    0% {
        left: 195%;
    }
    100% {
        left: 95%;
    }
}

.friedEgg {
    width: 23vw;
    position: absolute;

    margin-top: 14vw;
}

.egg1 {
    left: 105%;

    animation: friedEggMovement1 8s linear;
    animation-iteration-count: infinite;
}

.egg2 {
    left: 5%;

    animation: friedEggMovement2 8s linear;
    animation-iteration-count: infinite;
}

@keyframes friedEggMovement1
{
    0% {
        left: 105%;
    }
    100% {
        left: 5%;
    }
}

@keyframes friedEggMovement2
{
    0% {
        left: 5%;
    }
    100% {
        left: -95%;
    }
}