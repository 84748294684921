.body {
    overflow-x:hidden;
}

.headerRow {
    display: flex;
    margin-left: 4vw;
}

.brunchHeader {
    color: white;
    font-size: 8vw;
    text-align: left;
    text-shadow: 1px 1px black, -1px 1px black, 1px -1px black, -1px -1px black, 4px 4px #d6442b;

    margin-bottom: 0;
}

.windsor {
    font-size: 15vw;
    font-family: 'WindsorProUltHv';

    margin-top: 2vw;
    margin-left: 4vw;
}

.windsorSmall {
    font-size: 8vw;
    text-align: center;
    font-family: 'WindsorProUltHv';

    margin-top: 2vw;
    margin-left: 0vw;
}

.streetwear {
    font-family: 'Streetwear';
}

.reservation {
    font-size: 6vw;
    font-family: 'WindsorProUltHv';
    color: #d6442b;

    background-color: white;
    border-color: black;
    border-style: solid;
    border-radius: 100px;
    box-shadow: 5px 5px black;

    padding-top: 1vw;
    padding-bottom: 1vw;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-bottom: 3vw;
  }

  .reservation:hover {
    box-shadow: none;
  }

.reservationlink {
    text-decoration: none;
}

.subHeaderDropDown {
    display: flex;

    margin-left: 6vw;
}

.expandTriangle {
    width: 10vw;
    height: 5vw;
}

.expandTriangleFlipped {
    width: 10vw;
    height: 5vw;

    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);

    margin-left: 2vw;
    margin-top: 1vw;
}

.menuSubtext {
    font-size: 5vw;

    margin-top: 1vw;
}

.menuOption:hover {
    box-shadow: none;
  }
  
  .menu {
    color: #d6442b;
    font-size: 5vw;
    font-family: 'WindsorProUltHv';

    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  
  .menuDescription {
    text-decoration: wrap;
    text-align: left;

    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 0.5vw;
    margin-top: 2vw;
  }

  .menuDescriptionHeader {
    font-size: 4.5vw;
    font-family: 'WindsorProUltHv';

    margin-top: 0;
    margin-bottom: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .menuOption {
    flex: 40%;

    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 60px;
    box-shadow: 5px 5px black;

    padding-bottom: 2vw;
    margin-bottom: 30px;
    margin-left: 5%;
    margin-right: 5%;
  }