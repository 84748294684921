.successWindow {
    position: fixed;
    left: 20vw;
    top: 10vw;
    width: 60vw;
    height: 80vw;

    background-color: white;
    border-radius: 2vw;
    border: solid black 0.5vw;

    z-index: 20;
}

.successWindowMobile {
    position: fixed;
    left: 20vw;
    top: 20vw;
    width: 60vw;
    height: 120vw;

    background-color: white;
    border-radius: 2vw;
    border: solid black 0.5vw;
    z-index: 20;
}

.xButton {
    float: right;

    font-family: "WindsorProUltHv";
    font-size: 10vw;
    color: black;

    background-color: white;
    border-radius: 1vw;

    margin-right: 1vw;
    margin-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    padding-right: 1vw;
}

.discountText {
    text-align: center;
    font-family: 'WindsorProUltHv';
    font-size: 5vw;
    margin-top: 4vw;
    margin-bottom: 2vw;
    color: white;
    text-shadow: 0.5vw 0.5vw #d6442b;
    -webkit-text-stroke: 0.25vw black;
}

.formSuccessText {
    text-align: left;

    font-family: 'WindsorProUltHv';
    font-size: 2vw;
    color: black;

    background-color: white;
    border-radius: 20px;
    box-shadow: 1vw 1vw #d6442b;
    border: solid black 0.5vw;

    margin-bottom: 4vw;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 20px;
    padding: 10px;
}

.formSuccessTextMobile {
    font-size: 3vw;
}

.twentyOff {
    width: 90%;
    padding-left: 5%;
}

.classCode {
    text-align: center;
    font-family: 'WindsorProUltHv';
    font-size: 3.5vw;
    color: black;
    
    background-color: white;
    box-shadow: 1vw 1vw #d6442b;
    border-radius: 20px;
    border: solid black 0.5vw;

    margin-bottom: 4vw;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 10px;
    padding: 10px;
}
