.scroll-parent {
    position: relative;
    width: 100%;
    margin-top: 10px;
    background-color: white;
    text-align: center;
    font-family: 'WindsorProUltHv';
    color: #d6442b;
    font-size: 15px;
  }
  
  .scroll-element {
    width: inherit;
    height: inherit;
    position: absolute;
    left: 0%;
    top: 0%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

.primary {
    animation: primary 4s linear infinite;
    background-color: white;
  }
  
  .secondary {
    animation: secondary 4s linear infinite;
    background-color: white;
  }
  
  @keyframes primary {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  
  @keyframes secondary {
    from {
      left: 100%;
    }
    to {
      left: 0%;
    }
  }